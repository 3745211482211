import React from "react";
import { StaticQuery, graphql, Link } from "gatsby";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import Hidden from "@material-ui/core/Hidden";
import Button from '@material-ui/core/Button';
import { createTheme, ThemeProvider, withStyles } from '@material-ui/core/styles';
import Image from "./ImageDraw";

const theme = createTheme({
  palette: {
    primary: {
      main: '#ffffff'
    }
  },
});

const styles = theme => ({
  divider: {
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(1),
  },
  footer: {
    marginBottom: theme.spacing(1),
    whiteSpace: "nowrap",
    color: '#fff'
  },
});

const renderContact = () => {
  return (
    <div style={{textAlign: 'center', background: '#696969'}}>
      <h1 className='contact-title'>Contact Us</h1>
      <p className='contact-text'>
        ご不明な点がありましたらお気軽にご連絡ください。
      </p>
      <Link to='/contact/'>
        <Button
          variant="outlined"
          className='skallBottun'
          size="large"
          color='primary'
        >
          お問い合わせ
        </Button>
      </Link>
    </div>
  )
}

const Footer = withStyles(styles)(props => {
  const {
    classes,
    data: {
      site: {
        siteMetadata: {
          title,
          contact: { email, phone, location },
        },
      },
    },
    name: url
  } = props;

  // console.log(url)
  return (
    <>
      <ThemeProvider theme={theme}>
        {!url.match('/contact/') && renderContact()}
        <div id='buttonList'>
          <div style={{display: 'inline-block', textAlign: 'left'}}>
            <Link to='/'><Button className='linkBottun'>Home</Button></Link>
            <Link to='/news/'><Button className='linkBottun'>News</Button></Link>
            <Link to='/about/'><Button className='linkBottun'>About</Button></Link>
            <Link to='/service/'><Button className='linkBottun'>Service</Button></Link>
            <Link to='/recruit/'><Button className='linkBottun'>Recruit</Button></Link>
            <Link to='/partners/'><Button className='linkBottun'>Partners</Button></Link>
            <Link to='/contact/'><Button className='linkBottun'>Contact</Button></Link>
          </div>
        </div>
        <Divider className={classes.divider} />
        <footer className={classes.footer} id="footer">
          <span>
            <Image
                filename='AristoBrain株式会社.png'
                // caseStyle={{height: '4vw', width: '12vw', maxWidth: 200, maxHeight: 140, minWidth: 100, minHeight: 35, display: 'inline-block', overflow: 'hidden', position: 'relative'}}
                classN='footer-icon'
                imageStyle={{width: '100%', maxWidth: '100%', height: 'auto'}} />
            <Typography component="span" variant="caption" style={{fontSize: 'max(1vw, 10px)'}}>
              住所：{location}<br />
              電話番号：{phone}<br />
              商号：{title}<br />
              <br /><a href="https://www.youtube.com/channel/UC7LngwppiDD1BsaqNASqOhA" target="_blank" rel="noopener noreferrer" style={{textDecoration: 'underline'}}>YouTube</a><br />
              © Copyright {new Date().getFullYear()} {title.split('（')[0]}{", "}Corp. All rights reserved.
              {/* <Hidden only={["xs", "sm"]}>–</Hidden>
              <Hidden only={["xl", "lg", "md"]}>
                <br />
              </Hidden>{" "} */}
              {/* <br />
              &middot;
              <br />
              Starter created by{" "}
              <a href="https://foxandgeese.com">Fox and Geese</a> */}
            </Typography>
          </span>
        </footer>
      </ThemeProvider>
    </>
  );
});

export default ({name}) => (
  <StaticQuery
    query={graphql`
      query {
        site {
          siteMetadata {
            title
            contact {
              email
              phone
              location
            }
          }
        }
      }
    `}
    render={data => <Footer data={data} name={name} />}
  />
);
