import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import { Grid, Typography } from "@material-ui/core";
import withStyles from "@material-ui/styles/withStyles";
import "../css/style.styl";
import firebase from "gatsby-plugin-firebase";
// import Helmet from "react-helmet";

const flag = typeof window !== 'undefined';
let one_flag = true;
let prev_url = 'Home';

// let auth = null;
if (flag) {
  require("firebase/auth");
  require("firebase/analytics");
  // auth = firebase.auth();
}

const styles = {
  container: {
    marginTop: 0,
  },
  contentBox: {
    width: "100%",
  },
  title: {
    textAlign: "center",
  },
};

const Component = ({ children, classes, title, url}) => {
  // console.log(url)
  // console.log(typeof(url.toString()))

  if (flag & (one_flag | prev_url != url)) {
    firebase.analytics()
    firebase.analytics().setCurrentScreen(url)
    firebase.analytics().logEvent('enter_in_' + url.replace(/\//g, ''))
    // console.log(windowInstance.location.pathname)
    console.log('enter_in_' + url.replace(/\//g, ''))
    one_flag = false;
    prev_url = url;
  }

  return (
    <div style={{backgroundColor: '#202020', width: '100vw'}}>
      {/* <Helmet>
        <script async src="https://www.googletagmanager.com/gtag/js?id=UA-181516392-1">
        </script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
          
            gtag('config', 'UA-181516392-1');
          `}
        </script>
      </Helmet> */}
      <Header />
      <Grid
        className={classes.container}
        container
        direction="row"
        justify="center"
      >
        <Grid className={classes.contentBox} item>
          {title ? (
            <Typography className={classes.title} gutterBottom variant="h2">
              {title}
            </Typography>
          ) : null}
          {children}
          <Footer name={url}/>
        </Grid>
      </Grid>
    </div>
  );
};

export default withStyles(styles)(Component);
