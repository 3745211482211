import React from "react";
import { StaticQuery, graphql, Link } from "gatsby";
// import { Link } from "gatsby";
// import Menu from "./Menu";
// import MenuMobile from "./MenuMobile";
// import Hidden from "@material-ui/core/Hidden";
import Grid from "@material-ui/core/Grid";
import Toolbar from "@material-ui/core/Toolbar";
import AppBar from "@material-ui/core/AppBar";
// import Typography from "@material-ui/core/Typography";
// import Chip from "@material-ui/core/Chip";
// import Avatar from "@material-ui/core/Avatar";
// import { MaterialUi } from "mdi-material-ui";
import Img from "gatsby-image";
import MenuIcon from "mdi-material-ui/light/Menu";
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';

const theme = createTheme({
  MuiGridSpacing: {
    width: '100%',
    margin: '-32px 0'
  }
});

const Header = props => {

  // const homePageStyle = (theme) => ({
  //   root: {
  //     width: "300px"
  //   },
  //   hover: {
  //     '&.Mui-hover': {
  //         backgroundColor: "turquoise",
  //         color: "white",
  //         fontWeight: 600
  //     }
  //   }
  // });

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    // console.log(event.currentTarget)
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    // <ThemeProvider theme={theme}>
      <AppBar id="appBar">
        <Toolbar style={{width: '100vw'}}>
          <Grid alignItems="flex-start" container justify="space-between" spacing={4}>
            <Grid item style={{height: 42}}>
              <Img
                fixed={props.data.testFixed.childImageSharp.fixed}
                alt="AristoBrain株式会社"
                // style={{left: '5%', position: 'absolute', top: '20%', height: 42, width: 150}}
                className='header-icon'
                imgStyle={{height: 42, width: 150}}
              />
            </Grid>
            <Grid item>
              <IconButton edge="end" aria-label="menu">
                <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
                  <MenuIcon />
                </Button>
              </IconButton>
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                getContentAnchorEl={null}
                anchorOrigin={{ vertical: "bottom", horizontal: "left"}}
                transformOrigin={{ vertical: "top", horizontal: "left"}}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                selected
                // style={{}}
              >
                <Link to='/'><MenuItem onClick={handleClose}>Home</MenuItem></Link>
                <Link to='/news/'><MenuItem onClick={handleClose}>News</MenuItem></Link>
                <Link to='/about/'><MenuItem onClick={handleClose}>About</MenuItem></Link>
                <Link to='/service/'><MenuItem onClick={handleClose}>Service</MenuItem></Link>
                <Link to='/recruit/'><MenuItem onClick={handleClose}>Recruit</MenuItem></Link>
                <Link to='/partners/'><MenuItem onClick={handleClose}>Partners</MenuItem></Link>
                <Link to='/contact/'><MenuItem onClick={handleClose}>Contact</MenuItem></Link>
              </Menu>
              {/* <Hidden smDown>
                <Typography component="span" variant="caption">
                  <Menu />
                </Typography>
              </Hidden> */}
              {/* <Hidden mdUp>
                <MenuMobile />
              </Hidden> */}
            </Grid>
          </Grid>
          <Grid item />
        </Toolbar>
      </AppBar>
    // </ThemeProvider>
  );
};

export default () => (
  <StaticQuery
    query={graphql`
      query {
        site {
          siteMetadata {
            title
            contact {
              email
              phone
            }
          }
        }
        testFixed: file(relativePath: { eq: "AristoBrain株式会社.png" }) {
          childImageSharp {
            fixed(width: 100) {
                ...GatsbyImageSharpFixed
            }
          }
        }
      }
    `}
    render={data => <Header data={data} />}
  />
);
